import { coreServices } from './coreService';
import { API_BASE_URL, defaultHeaders } from './apiConfig';

export const studyServices = {
  create(authorization, data) {
    const url = `${API_BASE_URL}/users/studies/`;
    const headers = defaultHeaders(authorization);
    return coreServices.post(url, data, headers);
  },
  list(authorization) {
    const url = `${API_BASE_URL}/users/studies/`;
    const headers = defaultHeaders(authorization);
    return coreServices.get(url, headers);
  },
  update(authorization, internalId, data) {
    const url = `${API_BASE_URL}/users/studies/${internalId}/`;
    return coreServices.patch(url, data, defaultHeaders(authorization));
  },
  delete(authorization, internalId) {
    const url = `${API_BASE_URL}/users/studies/${internalId}/`;
    return coreServices.delete(url, defaultHeaders(authorization));
  },
};

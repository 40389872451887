<template>
  <div>
    <app-header />
    <nav-bar />

    <div id="myModal" class="modal" v-if="showModal">
      <ConfirmationModal
        :showModal="showModal"
        :onSuccess="loadPDFFile"
        @close="showModal = false"
      />
    </div>

    <v-container fluid style="background-color: #f7f7f7;">
      <v-row>
        <!-- Columna para el PDF --> 
        <v-col cols="8" class="d-flex justify-center">
          <div
            v-if="userAllowed"
            class="content-pdf"
            @contextmenu.prevent
            @copy.prevent
            @paste.prevent
          >
            <div class="pdf-container" v-if="pdfUrl">
              <div v-for="page in pages" :key="page">
                <VuePDF :pdf="pdfUrl" :page="page" :scale="scale" class="vue-pdf" />
              </div>
            </div>
            <div v-else class="circularloader-content">
              <CircularLoader :showLoading="!pdfUrl && userAllowed" />
            </div>
          </div>
          <div class="pdf-zoom-controls">
            <PDFZoomControls v-model="scale" v-if="pdfUrl && userAllowed" />
          </div>
        </v-col>

        <!-- Columna para la información del documento -->
        <v-col cols="4" class="document-information">
          <document-info
            v-if="documentInfo"
            :documentInfo="documentInfo"
            :userRole="userRole"
          />
          <br />
          <DocumentComments :documentId="route.params.id" :documentLoadedCorrectly="documentLoadedCorrectly" />
          <br />
          <DocumentValidation :documentId="route.params.id" :documentLoadedCorrectly="documentLoadedCorrectly" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import AppHeader from "../components/AppHeader.vue";
import NavBar from "../components/NavBar.vue";
import { useRoute } from "vue-router";
import { fileServices } from "../services/fileServices";
import { userServices } from "../services/userService";
import { VuePDF, usePDF } from '@tato30/vue-pdf';
import DocumentInfo from "../components/DocumentInfo.vue";
import DocumentComments from "../components/DocumentComments.vue";
import DocumentValidation from "../components/DocumentValidation.vue";
import PDFZoomControls from "../components/PDFZoomControls.vue";
import CircularLoader from "../components/CircularLoader.vue";
import ConfirmationModal from '../components/ConfirmationModal.vue';

export default {
  name: "EditStudy",
  components: {
    AppHeader,
    DocumentInfo,
    DocumentComments,
    DocumentValidation,
    PDFZoomControls,
    CircularLoader,
    ConfirmationModal,
    NavBar,
    VuePDF
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      showModal: true,
      documentInfo: null,
      authorization: Cookies.get("Authorization"),
      user: Cookies.get("user"),
      study: Cookies.get("study"),
      email: "",
      password: "",
      pdfUrl: null,
      userAllowed: false,
      alert: false,
      error: "",
      userRole: "",
      pages: 0,
      scale: 1.5,
      documentLoadedCorrectly: false
    };
  },
  computed: {
    formValid() {
      return !(this.email !== "" && this.password !== "");
    },
  },
  methods: {
    async loadPDFFile() {
      try {
        this.userAllowed = true;
        const response = await fileServices.getPDF(
          this.authorization,
          this.route.params.id
        );
        const { pdf, pages } = usePDF(response.config.url);
        this.pdfUrl = pdf;
        this.pages = pages;
        this.saveHistoric();
        this.documentLoadedCorrectly = true;
      } catch (error) {
        console.error("Failed to load PDF:", error);
      }
    },
    async getDocumentInformation() {
      const response = await fileServices.fileInformation(
        this.authorization,
        this.route.params.id
      );
      this.documentInfo = response.data;
    },
    async getPersonalUserInformation() {
      const response = await userServices.checkUserData(this.authorization);
      this.userRole = response.data.role;
    },
    saveHistoric() {
      this.file_id = this.route.params.id;
      const str = this.file_id.search("_");
      if (str == "_") {
        this.file_id = this.file_id.split("_")[1];
      }
      fileServices.saveHistoric(this.authorization, this.file_id);
    },
  },
  async mounted() {
    const role = localStorage.getItem("role");
    if (role === undefined) {
      await this.getPersonalUserInformation();
    } else {
      this.userRole = role;
    }
    await this.getDocumentInformation();
  },
};
</script>
<style lang="scss" scoped>
  @media print {
    html,
    body {
      display: none; /* hide whole page */
      background-color: #f7f7f7; //#ebeaeade;
    }
  }
  .modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
  }

  .circularloader-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50%;
  }
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .pdf-app .toolbar {
    z-index: 0 !important;
  }
  .pdf-app[class] {
    margin-left: 25px;
  }
  .document-information {
    margin-left: -10px;
  }

  .pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; /* Añade un margen superior para mover el contenido hacia abajo */
  }

  /* Asegura que el VuePDF no cause desbordamiento horizontal */
  .pdf-container > div {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-col.d-flex.justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .pdf-zoom-controls {
    position: fixed;
    bottom: 20px;
    left: 35%;
    transform: translateX(-50%);
    z-index: 10;
    padding: 10px;
    border-radius: 5px;
  }
  .vue-pdf {
    width: 100%; /* Ajusta el tamaño del PDF al contenedor */
  }
  .content-pdf {
    position: relative;
    width: 85%;
    height: 102vh;
    overflow: auto;
  }

</style>
<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" md="6" lg="6" class="mt-n12">
      <v-card class="terms-card">
        <v-toolbar dark class="card-title-custom toolbar-full-width">
          <v-toolbar-title class="title-custom">Terms and Conditions</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-area-custom-font">
          <p>
            Please take a moment to read and accept our
            Terms and Conditions before you start using ClinFAI.
            You can review the full document here.
            By checking the box below and clicking 'Accept', you agree
            to comply with and be bound by these terms.
          </p>
          <div class="document-viewer">
            <div class="content">
              <div
                v-if="pdfUrl"
                @contextmenu.prevent
                @copy.prevent
                @paste.prevent
              >
                <div v-for="(page, index) in pages" :key="index">
                  <VuePDF :style="{ overflow: 'auto' }" :pdf="pdfUrl" :page="page" :scale="scale" class="vue-pdf" />
                </div>
              </div>
              <div v-else>
                <CircularLoader
                  :showLoading="!pdfUrl"
                  icon="mdi-file-document-edit-outline"
                  showIcon="true"
                  class="circularloader-content"/>
              </div>
            </div>
          </div>
          <PDFZoomControls v-model="scale" v-if="pdfUrl" />
          <v-checkbox
            v-model="termsAccepted"
            label="I have read and accept the Terms and Conditions"
            class="mt-4"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!termsAccepted"
            style="background-color: #6E57F8;"
            class="white--text terms-accepted-btn"
            @click="acceptTerms"
          >Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-alert
      v-if="alert"
      :type="alertType"
      transition="scale-transition"
      class="alertbox"
    >
      {{ error }}
    </v-alert>
  </v-row>
</template>

<script>
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';
import CircularLoader from "../components/CircularLoader.vue";
import PDFZoomControls from "../components/PDFZoomControls.vue";
import { VuePDF, usePDF } from '@tato30/vue-pdf';

export default {
  name: 'AcceptTermsContent',
  components: {
    VuePDF,
    PDFZoomControls,
    CircularLoader
  },
  data() {
    return {
      termsAccepted: false,
      authorization: Cookies.get('Authorization'),
      pdfUrl: null,
      pages: null,
      documentId: null,
      alert: false,
      error: '',
      alertType: '',
      scale: 1
    };
  },
  mounted() {
    this.loadUnreadDocument();
  },
  methods: {
    async acceptTerms() {
      try {
        await userServices.markDocumentAsRead(this.authorization, this.documentId);
        this.$router.push('/home');
      } catch (error) {
        const errorMessage = error.data?.detail || 'Error trying to mark the document as read';
        this.showAlert(errorMessage, 'error');
      }
    },
    async markAsOpen(documentId) {
      try {
        await userServices.markDocumentAsOpen(this.authorization, documentId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error marking document as open:', error);
      }
    },
    async loadUnreadDocument() {
      try {
        const metadataResponse = await userServices.getUnreadDocumentMetadata(this.authorization);
        this.documentId = metadataResponse.data.id;
        const response = await userServices.getUnreadDocument(this.authorization);
        const { pdf, pages } = usePDF(response.config.url);
        this.pdfUrl = pdf;
        this.pages = pages;
        this.markAsOpen(this.documentId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading unread document:', error);
      }
    },
    showAlert(message, type) {
      this.alertType = type;
      this.error = message;
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, 4000);
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-height {
  margin-top: 55px;
  height: 100vh;
}

.terms-card {
  padding: 0;
  overflow: hidden;
}

.toolbar-full-width {
  width: 100%;
}

.document-viewer {
  height: 520px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Ajusta el espacio alrededor del contenido */
  margin-top: 20px; /* Añade un margen superior para mover el contenido hacia abajo */
}

.vue-pdf {
  margin-bottom: 11px; /* Espacio entre las páginas del PDF */
  border: 1px solid #ccc; /* Opcional: Añade un borde para visualizar mejor las páginas */
}

.circularloader-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 75%;
  padding-top: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.white--text {
  color: white !important;
}

.terms-accepted-btn {
  margin-right: 70px;
  margin-bottom: 20px;
}

.title-custom {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.alertbox {
  margin-top: 35px;
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 1000;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
</style>

<template lang="">
  <div class="expansion-container">
    <AlertNotification v-if="alert.active" :text="alert.message" :type="alert.type" />
    <GenericDeleteModal
      :showModal="deleteFileConfirmModal"
      :onSuccess="handleDeleteStudy"
      :currentId="currentId"
      :contentText="confirmTextModal"
      @close="deleteFileConfirmModal = false" />
    <v-dialog
      id="edit-study-modal-dialog"
      v-model="updateStudyDialog"
      persistent
      max-width="800px"
      class="rounded-xl"
    >
      <v-card id="edit-study-modal-card-dialog" class="title rounded-t-lg">
        <v-card-title class="card-title-custom" primary-title>
          <span id="text-study-dialog" class="bold-text">
            {{ isCreating ? 'Add New Study' : 'Edit Study' }}
          </span>
        </v-card-title>
        <v-card-text class="content-information">
          <v-container>
            <v-row>
              <div v-if="isCreating" class="full-width">
                <v-col cols="12">
                  <v-text-field 
                    id="add-study-name"
                    v-model="newStudyName"
                    label="Name"
                    color="#6E57F8"
                    outlined
                    :rules="this.studyNameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="add-study-acronym"
                    v-model="newStudyAcronym"
                    label="Acronym"
                    color="#6E57F8"
                    :rules="this.studyAcronymRules"
                    outlined>
                  </v-text-field>
                </v-col>
              </div>
              <div v-else class="full-width">
                <v-col cols="12">
                  <div class="info-row">
                    <strong class="left-text">Study: </strong> <span class="right-text">{{ studySelected.name }}</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="info-row">
                    <strong class="left-text">Acronym: </strong> <span class="right-text"> {{ studySelected.acronym }}</span>
                  </div>
                </v-col>
              </div>
              <v-col cols="12">
                <strong class="left-text">Date / Time availability range:</strong>
              </v-col>
              <!-- Fechas -->
              <v-col cols="12" md="5">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  content-class="custom-overlay--top"
                  transition="scale-transition"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      class="p-calendar"
                      v-model="formattedStartDateTime"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      v-bind="props"
                      color="#6E57F8"
                      outlined
                      required
                      :disabled="!isEditing"
                    ></v-text-field>
                  </template>
                  <v-card class="custom-calendar-card">
                    <p-calendar
                      v-model="startDateTime"
                      showTime
                      inline
                      hourFormat="24"
                      dateFormat="yy-mm-dd"
                      class="calendar-custom"
                    ></p-calendar>
                    <v-card-actions class="custom-card-action">
                      <v-btn text color="primary" @click="closeMenu('start')">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="5">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  content-class="custom-overlay--top"
                  transition="scale-transition"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-model="formattedEndDateTime"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      v-bind="props"
                      color="#6E57F8"
                      outlined
                      required
                      :disabled="!isEditing"
                    ></v-text-field>
                  </template>
                  <v-card class="custom-calendar-card">
                    <p-calendar
                      v-model="endDateTime"
                      showTime
                      inline
                      hourFormat="24"
                      dateFormat="yyyy-mm-dd"
                      class="calendar-custom"
                    ></p-calendar>
                    <v-card-actions class="custom-card-action">
                      <v-btn text color="primary" @click="closeMenu('end')">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" v-if="!isCreating">
                <!-- Botón de edición -->
                <v-btn
                  icon
                  v-if="!isEditing"
                  @click="isEditing = true"
                  title="Update"
                  class="icon-only-btn"
                >
                  <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
                <!-- Botón de cancelar edición de fechas -->
                <v-btn
                  icon
                  v-else
                  @click="isEditing = false"
                  title="Cancel Edit"
                  class="icon-only-btn"
                >
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-col>
              <!-- termina fechas -->
              <v-col cols="12" v-if="!isCreating">
                <span id="text-delete-dialog" class="warning-text">
                  <strong>Warning: </strong>Changing the date range of this study will automatically adjust both the start
                    and end dates/times of availability for any associated folders and documents within
                    those folders if they extend beyond the new study range. This adjustment ensures that
                    all associated data aligns with the updated study timeline. Please confirm to proceed
                    with these comprehensive adjustments.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close-edit-btn"
            class="btn-cancel"
            text
            @click="updateStudyDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="!isCreating"
            id="save-edit-btn"
            elevated
            :disabled="!isEditing"
            class="btn-accept"
            @click="updateStudy(studySelected.internal_id)"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            id="save-edit-btn"
            elevated
            :disabled="!isCreating"
            class="btn-accept"
            @click="createStudy()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expansion-panels class="expansion-card-admin-study-information">
      <v-expansion-panel>
        <v-expansion-panel-title class="title-container">
          <div class="study-modal-header">
            <v-icon class="mr-2" color="white" size="x-small">mdi-book-open-outline</v-icon>
            Studies
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-end btn-container">
              <v-spacer></v-spacer>
              <v-btn
                size="x-small"
                color="#6E57F8"
                class="white--text add-user-btn"
                @click="showAddStudyDialog"
              >
                <v-icon class="icon-circle mr-2">mdi-plus</v-icon>
                <span class="add-btn-text mr-2 ml-2">Add Study</span>
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="studies.length">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Acronym</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="study in studies" :key="study.internal_id">
                  <td>{{ study.name }}</td>
                  <td>{{ study.acronym }}</td>
                  <td>{{ formatDate(study.start_visualization_date) }}</td>
                  <td>{{ formatDate(study.end_visualization_date) }}</td>
                  <td class="action-btns">
                    <v-btn
                      small
                      fab
                      class="mr-1 ml-2"
                      color="#14C5E0"
                      title="Update study data"
                      @click="showModalForUpdateStudy(study)"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="!canDeleteStudy(study)"
                      class="mr-1 ml-2"
                      color="error"
                      @click="confirmDelete(study.internal_id)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>No Studies</div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { studyServices } from '../services/studyService';
  import DatePicker from 'primevue/datepicker';
  import AlertNotification from '../components/AlertNotification.vue';
  import GenericDeleteModal from "../components/GenericDeleteModal.vue";

  export default {
    name: 'AdminStudyInformation',
    props: {
      studies: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    components: {
      AlertNotification,
      GenericDeleteModal,
      'p-calendar': DatePicker,
    },
    data() {
      return {
        authorization: Cookies.get('Authorization'),
        updateStudyDialog: false,
        isCreating: false,
        isEditing: false,
        studySelected: {},
        newStudyName: null,
        newStudyAcronym: null,
        menuStartDate: false,
        menuEndDate: false,
        startDateTime: null,
        endDateTime: null,
        alert: { message: '', type: '', active: false },
        currentId: null,
        confirmTextModal: "Are you sure about deleting a study?",
        deleteFileConfirmModal: false,
        studyNameRules: [(v) => !!v || 'This field is required.',],
        studyAcronymRules: [
          (v) => !!v || 'This field is required.',
          (v) => !/\s/.test(v) || 'Acronym cannot contain spaces.',
          (v) => !/-/.test(v) || 'Acronym cannot contain hyphens.',
        ],
      };
    },
    computed: {
      formattedStartDateTime() {
        return this.startDateTime ? this.formatDate(this.startDateTime) : '';
      },
      formattedEndDateTime() {
        return this.endDateTime ? this.formatDate(this.endDateTime) : '';
      },
    },
    methods: {
      closeMenu(type) {
        if (type === 'start') this.menuStartDate = false;
        if (type === 'end') this.menuEndDate = false;
      },
      showAddStudyDialog() {
        this.isCreating = true;
        this.isEditing = true;
        this.updateStudyDialog = true;
        this.startDateTime = null;
        this.endDateTime = null;
      },
      showModalForUpdateStudy(study) {
        this.updateStudyDialog = true;
        this.studySelected = study;
        this.isCreating = false,
        this.isEditing = false;
  
        // Inicializar las fechas en el modal
        this.startDateTime = study.start_visualization_date;
        this.endDateTime = study.end_visualization_date;

      },
      async updateStudy(internalId) {
        const data = {
          start_visualization_date: this.startDateTime,
          end_visualization_date: this.endDateTime,
        };
        try {
          const response = await studyServices.update(this.authorization, internalId, data);
          
          // Actualizar las fechas en el estudio seleccionado
          this.studySelected.start_visualization_date = response.data.start_visualization_date;
          this.studySelected.end_visualization_date = response.data.end_visualization_date;

          this.showAlert('Study dates updated correctly', 'success');

          this.isEditing = false;
          this.updateStudyDialog = false;
        } catch (error) {
          if (error && error.data && error.data.non_field_errors) {
            this.showErrorAlert(error.data.non_field_errors[0])
          } else {
            this.showErrorAlert('Error trying to modify study dates');
          }
        }
      },
      async createStudy() {
        const data = {
          name: this.newStudyName,
          acronym: this.newStudyAcronym,
          start_visualization_date: this.startDateTime,
          end_visualization_date: this.endDateTime,
        };
        try {
          const response = await studyServices.create(this.authorization, data);
          if (response.status == 201) {
            this.$emit('study-event', response.data);
            this.showAlert('Study created correctly', 'success');
          }
          this.isCreating = false;
          this.isEditing = false;
          this.updateStudyDialog = false;
          this.newStudyName = null;
          this.newStudyAcronym = null;
        } catch (error) {
          if (error && error.response && error.response.data && error.response.data.non_field_errors) {
            this.showErrorAlert(error.response.data.non_field_errors[0])
          } else {
            this.showErrorAlert('Error trying to create new study');
          }
        }
      },
      async confirmDelete(internalId) {
        this.currentId = internalId
        this.deleteFileConfirmModal = true;
      },
      async handleDeleteStudy() {
        try {
          const response = await studyServices.delete(this.authorization, this.currentId);
          this.currentId = null;
          this.$emit('study-event', response.data);
          this.showAlert('Study deleted correctly', 'success');
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.detail) {
              this.showErrorAlert(error.response.data.detail)
            } else {
              this.showErrorAlert('Error trying to delete new study');
            }
        }
        this.deleteFileConfirmModal = false;
      },
      canDeleteStudy(study) {
        return study.can_be_deleted;
      },
      formatDate(date) {
        return date ? new Date(date).toLocaleString() : '';
      },
      showAlert(message, type) {
        this.alert = { message, type, active: true };
        this.$nextTick(() => {
          setTimeout(() => (this.alert.active = false, this.alert.message = ''), 4000);
        });
      },
      showErrorAlert(message) {
        this.showAlert(message, 'error');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .action-btns {
    text-align: center;
  }
  .datetime-field {
    margin: 2px;
    max-width: 200px;
  }
  .calendar-custom {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .add-user-btn {
    background-color: #6E57F8;
    height: 42px !important;
    width: 140px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center; 
  }
  .btn-container {
    display: flex;
    justify-content: flex-end; /* Alinear a la derecha */
    margin-bottom: 10px;
    margin-top: 18px;
    padding-right: 24px; /* Añade un pequeño padding si lo deseas */
  }

  .title-container {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
    border-radius: 8px 8px 0 0; /* Redondeo solo en las esquinas superiores */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .study-modal-header {
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
    margin-left: 5px;
    color: #FFFFFF;
    font-size: 1.2em;
  }

  .expansion-card-admin-study-information {
    border-radius: 16px !important;
    width: 100%;
    margin-top: 32px;
  }

  .expansion-container {
    display: flex;
    justify-content: center !important;
    font-family: 'Poppins', sans-serif;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }

  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .custom-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .icon-only-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    min-width: auto !important;
  }
  .info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .left-text {
    text-decoration: underline;
    font-weight: 550;
    font-family: 'Poppins', sans-serif;
  }

  .right-text {
    text-align: right;
    margin-right: 40px;
    font-family: 'Poppins', sans-serif;
  }
  .warning-text {
    color: red;
    display: block;
    text-align: center;
  }
  .icon-circle {
    color: white; /* Color del ícono */
    border: 2px solid white; /* Borde blanco alrededor del ícono */
    border-radius: 50%; /* Hacer el borde redondeado */
    padding: 5px; /* Espacio alrededor del ícono para el borde */
    width: 24px; /* Ajustar tamaño del círculo */
    height: 24px; /* Ajustar tamaño del círculo */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-btn-text {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 550;
  }
  .full-width {
    width: 100%;
  }
  .content-information {
    padding: 24px;
    overflow-x: hidden; /* Ocultar el scroll horizontal */
  }
  .bold-text {
    font-size: 20px;
    font-weight: bold; /* También puedes usar "700" */
  }
</style>

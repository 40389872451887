<template>
  <div class="main-container">
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <AdminUserList :studiesList="studiesList"/>
      <AdminStudyInformation :studies="studiesList" @study-event="handleStudyEvent"/>
    </v-container>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import AppHeader from '../components/AppHeader.vue';
  import NavBar from '../components/NavBar.vue';
  import AdminUserList from '../components/AdminUserList.vue';
  import AdminStudyInformation from '../components/AdminStudyInformation.vue';
  import { userServices } from '../services/userService';
  import { studyServices } from '../services/studyService';

  export default {
    name: 'AdminView',
    components: {
      AppHeader,
      NavBar,
      AdminUserList,
      AdminStudyInformation
    },
    data() {
      return {
        authorization: Cookies.get('Authorization'),
        role: Cookies.get('role'),
        studiesList: [],
      };
    },
    mounted() {
      const role = localStorage.getItem('role');
      if (role === undefined) {
        this.checkUserRole();
      } else {
        this.role = role;
      }
      this.getStudies();
    },
    methods: {
      checkUserRole() {
        userServices.checkUserData(this.authorization)
          .then((response) => {
            this.role = response.data.role;
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
      },
      async getStudies() {
        studyServices.list(this.authorization)
          .then((response) => {
            this.studiesList = response.data.map(item => ({
              id: item.id,
              acronym: item.acronym,
              name: item.name,
              start_visualization_date: item.start_visualization_date,
              end_visualization_date: item.end_visualization_date,
              internal_id: item.internal_id,
              can_be_deleted: item.can_be_deleted,
            }));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      handleStudyEvent() {
        this.getStudies()
      },
    },
  };
</script>
<style scoped>
  .admin-content {
    flex: 1;
    padding: 20px;
  }
  .form-style {
    padding-left: 24px;
  }
  @media screen and (max-width: 470px) {
    .admin-card-style {
      margin-left: 0;
      max-width: 100%;
    }
    .form-style {
      padding-left: 24px;
    }
    .admin-content {
      padding: 10px 34px !important;
    }
  }
</style>

<template>
  <div>
    <app-header />
    <nav-bar />
    <v-container fluid class="container-styles">
      <div id="myModal" class="modal" v-if="deleteFileConfirmModal">
        <ConfirmationModal
          :showModal="deleteFileConfirmModal"
          :onSuccess="deleteFile"
          :title=deleteFileConfirmModalTitle
          :subtitle=deleteFileConfirmModalSubTitle
          @close="deleteFileConfirmModal = false" />
      </div>

      <div class="content">
        <AlertNotification v-if="alert.active" :text="alert.message" :type="alert.type" />
        <v-row >
          <v-col md="5" class="d-flex flex-column justify-content-center text-center tree-container">
            <v-card class="library-tree-card">
              <v-card-title class="ml-8 mt-8" style="font-family:'Poppins'"><strong>Folder tree</strong></v-card-title>
              <v-treeview
                :items="items"
                item-value="folder_id"
                item-key="name"
                class="ml-8"
                open-on-click
              >
                <template v-slot:prepend="{ item, isOpen }">
                  <v-dialog
                    v-if="!item.file && role != 'Viewer'"
                    v-model="showNewFolderModal"
                    persistent
                    transition="dialog-top-transition"
                    max-width="600px"
                  >
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        size="x-small"
                        :class="['mr-1', 'round-button', { 'round-button-active': item.folder_id === activeFolderId }]"
                        icon="mdi-plus"
                        @click="setCurrentFolder(item.folder_id)"
                      >
                      </v-btn>
                    </template>
                    <v-card id="add-new-folder-dialog" v-if="role != 'Viewer'" class="rounded-t-lg" elevated>
                      <v-card-title class="card-add-new-folder-title">
                        <span id="add-comment-span-dialog" class="bold-text">
                          Add new folder
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-model="folderName"
                          label="folder name*"
                          :rules="[
                            () => !!folderName || 'This field is required',
                            () => !!folderName && folderName.length <= 40 || 'Folder name must be less than 40 characters',
                            folderCheck
                          ]"
                          required color="#6E57F8"/>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          class="btn-cancel"
                          text
                          @click="showNewFolderModal = false; folderName = null"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          class="btn-accept"
                          :disabled="!folderName"
                          @click="addChildFolder(currentFolderId)"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- Icono de carpeta o archivo -->
                  <v-icon
                    v-if="!item.file"
                    @click="getFolderId(item)"
                    :class="{ 'folder-open-color': activeFolderId === item.folder_id }"
                  >
                    {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <v-icon v-else>
                    {{ files[item.file] }}
                  </v-icon>

                  <!-- Bloque para archivos -->
                  <div style="display: inline">
                    <!-- Icono de validación solo para archivos -->
                    <v-icon v-if="item.file && item.validation" class='icon-valid'>
                      {{ getValidatedIcon(item.validation) }}
                    </v-icon>
                    <!-- Icono de eliminación solo para archivos -->
                    <v-icon
                      v-if="item.file && deleteItemEnabled"
                      medium
                      color="red darken-2"
                      @click.stop="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                    <router-link
                      class="file-link"
                      :to="`/library/document/${item.internal_id}/edit`"
                      v-if="item.file"
                    >
                      <span>{{ item.name }}</span>
                    </router-link>
                    <span
                      v-else @click="getFolderId(item)"
                      :class="{ 'folder-open-color': activeFolderId === item.folder_id }"
                      :id="`folder-${item.folder_id}`"
                    >
                      {{ item.name }}
                    </span>
                  </div>
                </template>
              </v-treeview>
            </v-card>
          </v-col>
          <!-- Columna para mostrar los archivos en la carpeta seleccionada -->
          <v-col md="7" class="d-flex flex-column justify-content-center text-center">
            <!-- Mostrar FolderDataPicker solo si el role es 'Updater' -->
            <div v-if="role === 'Updater'">
              <FolderDataPicker
                v-if="role === 'Updater'"
                :folderName="currentfolderName"
                :folderStartDate="folderStartDate"
                :folderEndDate="folderEndDate"
                :currentFolderId="currentFolderId"
                :userRole="role"
              />
              <br>
            </div>
            <v-card class="tableStyle">
              <v-col cols="12" class="card-title-custom">
                <v-toolbar-title><span class="bold-text">Files in the selected folder</span></v-toolbar-title>
              </v-col>
              <v-col class="content-information">
                <v-text-field
                color="rgb(101,123,219)"
                v-model="search"
                append-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-data-table :headers="headers" :items="documentos" :search="search">
                <template v-slot:[`item.title`]="{ item }">
                  <router-link :to="`/library/document/${item.internal_id}/edit`">
                    {{ item.title }}
                  </router-link>
                </template>
                  <!-- Columna de validación -->
                <template v-slot:[`item.validation`]="{ item }">
                  <v-icon :class="item.validated ? 'icon-valid' : 'icon-invalid'">
                    {{ item.validationIcon }}
                  </v-icon>
                </template>
              </v-data-table>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import AppHeader from '../components/AppHeader.vue';
  import NavBar from '../components/NavBar.vue';
  import { VTreeview } from 'vuetify/labs/VTreeview';
  import { fileServices } from '../services/fileServices';
  import { userServices } from '../services/userService';
  import { validationServices } from '../services/validationService';
  import ConfirmationModal from '../components/ConfirmationModal.vue';
  import FolderDataPicker from '../components/FolderDataPicker.vue';
  import AlertNotification from '../components/AlertNotification.vue';

  export default {
    name: 'LibraryView',
    components: {
      AppHeader,
      NavBar,
      VTreeview,
      ConfirmationModal,
      FolderDataPicker,
      AlertNotification
    },
    data() {
      return {
        email: '',
        password: '',
        registerName: '',
        registerLastName: '',
        role: '',
        showNewFolderModal: false,
        search: '',
        fileId: '',
        user: Cookies.get('user'),
        authorization: Cookies.get('Authorization'),
        study: Cookies.get('study'),
        files: {
          pdf: 'mdi-file',
          png: 'mdi-file-image',
          txt: 'mdi-file-document-outline',
        },
        tree: [],
        items: [],
        folder: '',
        documentos: [],
        headers: [
          { title: 'File', value: 'title', align: 'center'},
          { title: 'Creator', value: 'user', align: 'center'},
          { title: 'Created date (Time zone GMT)', value: 'historic_timestamp', align: 'center'},
          { title: 'Validation', value: 'validation', align: 'center'},
        ],
        alert: { message: '', type: '', active: false },
        folderName: null,
        deleteItemEnabled: false,
        selectedFolder: {},
        deleteFileConfirmModal: false,
        deleteFileConfirmModalTitle: null,
        deleteFileConfirmModalSubTitle: null,
        fileToDelete: '',
        currentfolderName: null,
        currentFolderId: null,
        activeFolderId: null,
        folderStartDate: null,
        folderEndDate: null,
      };
    },
    computed: {
      formValid() {
        return !(this.email && this.password);
      },
    },
    mounted() {
      const role = localStorage.getItem('role');
      if (role === undefined) {
        this.checkUserData();
      } else {
        this.role = role;
        if (this.canDeletePermissions(this.role)) {
          this.deleteItemEnabled = true;
        }
      }
      this.getFolders();
    },
    methods: {
      checkUserData() {
        userServices
          .checkUserData(this.authorization)
          .then((response) => {
            this.registerName = response.data.first_name;
            this.registerLastName = response.data.last_name;
            this.role = response.data.role;
            if (this.canDeletePermissions(this.role)) {
              this.deleteItemEnabled = true;
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      },
      getFolders() {
        if (this.items.length > 0) {
          this.items = [];
        }
        fileServices
          .getItems(this.authorization, this.study)
          .then((response) => {
            const { data } = response;
            this.items.push(data);
            this.getFolderId(this.items[0]);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error folder:', error);
            this.showErrorAlert('Error trying to get folder and documents');
          });
      },
      getValidatedIcon(validation) {
        return validationServices.getValidatedIcon(validation);
      },
      getValidated(validation) {
        if (!validation) {
          return null;
        }
        return validation.validated;
      },
      deleteFile() {
        fileServices
          .deleteFile(this.authorization, this.fileToDelete.internal_id, this.fileToDelete.parent, this.study)
          .then((resp) => {
            if (resp.status === 204) {
              this.deleteFileConfirmModal = false;
              this.deleteFileConfirmModalTitle = null;
              this.deleteFileConfirmModalSubTitle = null;
              this.items = [];
              this.documentos = [];
              this.email = '';
              this.password = '';
              this.getFolders();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error deleteItem:', error);
            this.showErrorAlert('There was an error trying to delete the file, please try again later');
          });
      },
      getFolderId(item) {
        this.documentos = [];
        this.activeFolderId = item.folder_id;
        fileServices
          .getInformation(this.authorization, item.folder_id)
          .then((response) => {
            response.data.forEach((document) => {
              if (document.internal_id) {
                const obj = {
                  timestamp: document.timestamp,
                  user: document.user.email,
                  title: document.title,
                  file_id: `${document.file_id}`,
                  internal_id: document.internal_id,
                  validationIcon: validationServices.getValidatedIconForDT(document.validations[0] || {}),
                  validated: this.getValidated(document.validations[0] || {}),
                };
                // Formatear el timestamp
                const [date, time] = obj.timestamp.split('T');
                const formattedTimestamp = `${date.replace(/-/g, '/')} ${time.slice(0, 5)}`;
                obj.historic_timestamp = formattedTimestamp;
                this.documentos.push(obj);
              }
              this.selectedFolder = response.data[0];
            });
            this.selectedFolder = response.data[0];
            this.currentFolderId = item.folder_id;
            if (this.selectedFolder) {
              this.currentfolderName = this.selectedFolder.folder.name || null;
              this.folderStartDate = this.selectedFolder.folder.start_visualization_date || null;
              this.folderEndDate = this.selectedFolder.folder.end_visualization_date || null;
            } else {
              this.currentfolderName = null;
              this.folderStartDate = null;
              this.folderEndDate = null;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error folder:', error);
          });
      },
      setCurrentFolder(folderId) {
        this.showNewFolderModal = true;
        this.currentFolderId = folderId;
      },
      addChildFolder(currentFolderId) {
        fileServices
          .addFolder(this.authorization, currentFolderId, this.folderName)
          .then((response) => {
            if (response.status === 201) {
              this.showNewFolderModal = false;
              this.folderName = null;
              this.currentFolderId = null;
              this.getFolders();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error addChildFolder:', error);
          });
      },

      deleteItem(item) {
        this.deleteFileConfirmModalTitle = "Are you sure to delete file '" + item.name + "' ?";
        this.deleteFileConfirmModalSubTitle = "To confirm, please enter your email and password:";
        this.deleteFileConfirmModal = true;
        this.fileToDelete = item;
      },
      canDeletePermissions(role) {
        const allowedRoles = ['Admin', 'Superadmin', 'Updater'];
        return allowedRoles.includes(role);
      },
      showAlert(message, type) {
        this.alert = { message, type, active: true };
        this.$nextTick(() => {
          setTimeout(() => (this.alert.active = false, this.alert.message = ''), 4000);
        });
      },
      showErrorAlert(message) {
        this.showAlert(message, 'error');
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    font-family: 'Poppins', sans-serif;
  }

  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .alertbox {
    margin-bottom: 16px;
  }

  .tableStyle {
    font-family: 'Poppins', sans-serif;
    max-width: 100%; 
    width: 100%;
    overflow-x: hidden;
    border-spacing: 0 15px;
  }

  .v-dialog {
    border-radius: 16px !important;
  }

  .v-treeview-node--active {
    background-color: #e0e0e0 !important;
  }

  .content {
    margin-top: 30px;
  }

  .library-tree-card {
    padding-bottom: 50px;
  }

  .v-application {
    .text-center {
      text-align: initial !important;
    }

    a {
      color: black !important;
      text-decoration: none;

      &:hover {
        color: #1976d2 !important;
      }
    }
  }

  .form-input {
    margin-bottom: 20px;
  }

  .file-link {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon-valid {
    color: #6E57F8 !important;
    font-weight: bold;
    font-size: 24px;
  }

  .icon-invalid {
    color: red;
    font-weight: bold;
    font-size: 24px;
  }
  .bold-text {
    font-size: 20px;
    font-weight: bold; /* También puedes usar "700" */
  }

  .card-add-new-folder-title {
    background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
    padding: 24px; // Espacio alrededor del contenido
    border-top-left-radius: 8px; // Redondear esquina superior izquierda
    border-top-right-radius: 8px; // Redondear esquina superior derecha
    border-bottom-left-radius: 0; // Borde inferior izquierdo sin redondear
    border-bottom-right-radius: 0; // Borde inferior derecho sin redondear
    color: #ffffff; // Color del texto en blanco
    width: 100%; // Asegura que ocupe todo el ancho del contenedor
    display: flex; // Alinea el contenido dentro del contenedor
    align-items: center; // Centra verticalmente
    justify-content: space-between; // Alinea el contenido horizontalmente
  }
  .content-information  {
    padding: 24px;
    overflow-x: hidden; /* Ocultar el scroll horizontal */
  }
  .folder-open-color {
    color: #391aec !important; // Color morado para el ícono de carpeta activa
  }
  .round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fdfcfc;
    border: 1px solid #BDBDBD; /* Gris claro para el borde sin seleccionar */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    padding: 8px;
    width: 30px;
    height: 30px;

    .v-icon {
      color: #1d1d1d; /* Color morado para el ícono de añadir */
      font-size: 18px;
    }
  }
  .round-button-active {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;  /* Mantener el botón redondeado */
    background-color: #fdfcfc; /* Fondo blanco */
    border: 1px solid #391aec; /* Borde morado más fino */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    padding: 8px; /* Ajuste del padding para que el botón sea más pequeño */
    width: 30px; /* Reducción del tamaño */
    height: 30px; /* Asegura que el alto y ancho sean iguales */
  }
  :deep(.v-overlay__scrim) {
    background-color: rgba(0, 0, 0, 0.1) !important; /* Fondo blanco por defecto */

    box-shadow: none !important; /* Elimina el sombreado */
  }
  :deep(.v-dialog) {
    box-shadow: none !important; /* Eliminar sombra del v-dialog */
  }

  :deep(.v-overlay__content) {
    box-shadow: none !important; /* Eliminar sombra del contenido del overlay */
  }
  .v-card--variant-elevated {
      box-shadow: none !important;
  }
</style>

<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" md="6" lg="6">
      <v-card class="verify-otp-card">
        <v-toolbar dark class="card-title-custom toolbar-full-width">
          <v-toolbar-title class="title-custom"><h3>Verify Your Account</h3></v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-area-custom-font">
          <p>
            Please enter the 6-digit code sent to your email. 
            If you didn't receive it, check your spam folder or request a new OTP.
          </p>

          <!-- Input field for OTP code -->
          <v-sheet color="surface">
            <v-otp-input
              ref="otpInput"
              v-model="OTPCode"
              type="text"
              :error="hasOtpError"
            ></v-otp-input>
          </v-sheet>

          <v-row justify="center" class="mt-4 mb-4">
            <v-btn
              :disabled="!OTPCode || OTPCode.length !== 6"
              style="background-color: #6E57F8;"
              class="white--text verify-btn"
              @click="verify"
            >Verify
            </v-btn>
          </v-row>

          <div class="text-caption text-center">
            Didn't receive the code? <a href="#" @click.prevent="resendOTP()" class="resend-link">Resend</a>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-alert
      v-if="alert"
      :type="alertType"
      transition="scale-transition"
      class="alertbox"
    >
      {{ error }}
    </v-alert>
  </v-row>
</template>


<script>
  import Cookies from 'js-cookie';
  import { userServices } from '../services/userService';

  export default {
    name: 'VerifyOTPForm',
    data() {
      return {
        authorization: Cookies.get('Authorization'),
        OTPCode: '',
        alert: false,
        error: '',
        hasOtpError: false,
        alertType: '',
      };
    },
    mounted() {
      // Focus the OTP input field when the component is mounted
      this.$refs.otpInput.$el.querySelector('input').focus();
    },
    watch: {
      OTPCode(newCode) {
        // Verificar si el código tiene la longitud deseada (ej. 6 dígitos)
        if (newCode && newCode.length === 6) {
          this.verify();
        }
      },
    },
    methods: {
      async verify() {
        if (this.OTPCode.length === 6) {
          try {
            await userServices.verifyOTP(this.authorization, this.OTPCode);
            this.$router.push('/home');
          } catch (error) {
            const errorMessage = error.data?.detail || 'Error verifying OTP';
            this.hasOtpError = true;
            this.OTPCode = '';
            this.showAlert(errorMessage, 'error');
          }
        }
      },
      async resendOTP() {
        try {
          await userServices.resendOTP(this.authorization);
          this.showAlert('A new OTP has been sent to your email.', 'success');
        } catch (error) {
          if (error.status == 429) {
            const errorMessage = 'Please wait to request a new code';
            this.showAlert(errorMessage, 'error');
          } else {
            const errorMessage = error.data?.detail || 'Error resending OTP';
            this.showAlert(errorMessage, 'error');

          }
        }
      },
      showAlert(message, type) {
        this.alertType = type;
        this.error = message;
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
          this.hasOtpError = false;
        }, 4000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fill-height {
    margin-top: 5%;
    height: 100vh;
  }

  .verify-otp-card {
    padding: 0;
    overflow: hidden;
  }

  .toolbar-full-width {
    width: 100%;
  }

  .verify-btn {
    margin-right: 15px;
    margin-bottom: 20px;
    background-color: #6E57F8 !important;
    color: white !important;
  }

  .resend-link {
    color: #6E57F8;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .alertbox {
    margin-top: 35px;
    position: fixed;
    top: 50px;
    right: 40px;
    z-index: 1000;
    max-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
</style>

<template>
  <div>
    <v-card>
      <v-container fluid class="document-information-style">
        <v-row>
          <v-col cols="12" class="card-title-custom">
            <v-toolbar-title class="title-text-validations">
              Validation
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-toolbar-title>
          </v-col>
        </v-row>
        <div class="content-information">
          <v-row v-if="validation">
            <div class="validation-label"><strong>Status:</strong></div>
            <div class="validation-content">
              <span class="status-icon" v-if="validation.validated">
                <v-icon>mdi-check</v-icon> <strong>Validated</strong>
              </span>
              <span class="status-icon" v-if="!validation.validated">
                <v-icon>mdi-close</v-icon> <strong>Non-Validated</strong>
              </span>
              <br>
              <span class="validated-by">(by {{ validation.created_by_user_text }}</span>
              <span v-if="validation.time_from_creation" class="validated-time">
                {{ validation.time_from_creation }})
              </span>
            </div>
          </v-row>
          <v-row v-if="validation && validation.text">
            <div class="validation-label"><strong>Reason:</strong></div>
            <div class="validation-content">
              {{ validation.text }}
            </div>
          </v-row>
          <v-row v-if="validation && validation.is_current_user">
            <div class="validation-label"><strong>Actions:</strong></div>
            <div class="validation-content">
              <v-btn
                id="edit-validation-btn"
                text-color="white"
                variant="plain"
                elevation="0"
                @click="dialogAddValidation = true"
                class="edit"
              >
                <v-icon>mdi-pencil</v-icon> Update validation
              </v-btn>
            </div>
          </v-row>

          <v-col v-if="!validation && documentLoadedCorrectly" cols="12" class="no-validations">
            Not validated
          </v-col>
          <v-col v-else class="load-items" cols="12">
            <CircularLoader :showLoading="!documentLoadedCorrectly" :width="5" :size="50" />
          </v-col>
          <v-dialog
            id="add-validation-modal-dialog"
            v-model="dialogAddValidation"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ props }">
              <div class="mx-auto pb-2 d-flex justify-center">
                <v-btn
                  id="add-validation-btn"
                  color="#6E57F8"
                  text-color="white"
                  dark
                  v-bind="props"
                  v-if="!validation"
                  :disabled="!documentLoadedCorrectly"
                  @click="dialogAddValidation = true; validation_text=''"
                  class="mx-auto custom-add-validation-btn"
                >
                  VALIDATE
                </v-btn>
              </div>
            </template>

            <v-card id="add-validation-card-dialog" class="rounded-t-lg">
              <v-card-title class="card-title-custom">
                <span id="add-validation-span-dialog" class="title-text-validations">
                  New Validation
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-checkbox
                      v-model="validate"
                      label="Validate document"
                      font-family="Poppins, sans-serif"
                      variant="outlined"
                      required
                      color="#6E57F8"
                      auto-grow
                    ></v-checkbox>
                  </v-row>
                  <v-row>
                    <v-textarea
                      class="custom-textarea text-area-custom-font"
                      v-model="validation_text"
                      label="Validation text"
                      variant="outlined"
                      required
                      color="#6E57F8"
                      auto-grow
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="btn-cancel" text @click="dialogAddValidation = false">
                  Close
                </v-btn>
                <v-btn
                  elevated
                  class="btn-accept"
                  :disabled="!validation_text"
                  @click="saveValidation(newValidation)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import moment from 'moment';
  import { validationServices } from '../services/validationService';
  import CircularLoader from "../components/CircularLoader.vue";

  moment.locale('es');

  export default {
    name: 'DocumentValidation',
    components: {
      CircularLoader,
    },
    props: {
      documentId: String,
      documentLoadedCorrectly: Boolean,
    },
    data() {
      return {
        validation: null,
        validate: false,
        currentUser: Cookies.get('user'),
        dialogAddValidation: false,
        validation_text: '',
        newValidation: {},
        authorization: Cookies.get('Authorization'),
      };
    },
    async mounted() {
      if (this.documentLoadedCorrectly) {
        await this.getValidation();
      }
    },
    watch: {
      // Watcher para detectar cambios en documentLoadedCorrectly
      async documentLoadedCorrectly(newVal) {
        if (newVal) {
          await this.getValidation();
        }
      },
    },
    methods: {
      async getValidation() {
        try {
          const response = await validationServices.listByDocumentId(
            this.authorization, this.documentId,
          );
          const validation = response.data[0];
          if (validation) {
            const formattedValidation = {
              ...validation,
              created_by_user_text: validation.validated_by.email,
              time_from_creation: moment(validation.timestamp).fromNow(),
              is_current_user: this.currentUser === validation.validated_by.email,
            };

            this.validation = formattedValidation;
            this.validation_text = validation.text;
            this.validate = validation.validated;
          } else {
            this.validation = null;
            this.validation_text = null;
            this.validate = false;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching validation:', error);
          this.validation = null;
          this.validation_text = null;
          this.validate = false;
        }
      },
      async deleteValidation(validation) {
        try {
          await this.$confirm('Are you sure?');
          await validationServices.delete(this.authorization, validation.id);
          await this.getValidation();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async saveValidation() {
        const newValidation = {
          document_id: this.documentId,
          text: this.validation_text,
          validated: this.validate,
        };
        if (!this.validation) {
          await validationServices.create(this.authorization, newValidation);
        } else {
          await validationServices.update(
            this.authorization, newValidation, this.validation.id,
          );
        }
        await this.getValidation();
        this.dialogAddValidation = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .title-text-validations {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .validation-label,
  .validation-content {
    padding-left: 24px;
    margin-top: 5px;
  }
  .status-icon {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
  }
  /* Alinear contenido con el título */
  .validation-label,
  .validation-content,
  .status-icon,
  .validated-by,
  .validated-time,
  .no-validations {
    padding-left: 10px; /* Alineación uniforme */
    margin-left: 0; /* Asegurarse de que no haya margen extra */
    padding-top: 5px;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .load-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajusta la altura según sea necesario */
  }
  .content-information {
    padding: 24px; /* Uniforme alrededor para no superponerse */
    padding-top: 16px; /* Separación adicional del título */
    overflow-x: hidden;
  }
</style>

<template lang="">
  <div>
    <div class="expansion-container">
      <v-expansion-panels class="expansion-card-terms-and-conditions">
        <v-expansion-panel>
          <v-expansion-panel-title class="title-container">
            Terms and conditions
          </v-expansion-panel-title>
          <v-expansion-panel-text v-if="documents.length">
            <table class="documents-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(document, index) in documents"
                  :key="index"
                >
                  <td>
                    <v-icon class="status-icon">
                      {{ document.status ? 'mdi-check' : 'mdi-close-circle' }}
                    </v-icon>
                  </td>
                  <td>
                    <a href="#" @click.prevent="handleDocumentClick(document)">
                      {{ document.document_name }}
                    </a>
                  </td>
                  <td>
                    {{ document.read_timestamp ?
                    new Date(document.read_timestamp).toLocaleString() : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-expansion-panel-text>
          <v-expansion-panel-text v-else cols="12">
            No documents
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { userServices } from '../services/userService';

  export default {
    name: 'UserDocumentsReads',
    data() {
      return {
        documents: [],
        authorization: Cookies.get('Authorization'),
      };
    },
    mounted() {
      this.fetchUnReadDocuments();
    },
    methods: {
      async fetchUnReadDocuments() {
        try {
          const response = await userServices.getUserApplicationDocumentsReads(this.authorization);
          this.documents = response.data;
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      },
      async handleDocumentClick(documentItem) {
        try {
          const response = await userServices.downloadDocument(
            this.authorization, documentItem.document_id,
          );
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentItem.document_name);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error al descargar el archivo:', error);
        }
      },
    },
  };
</script>

<style scoped>
  .title-container {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
  }
  .expansion-card-terms-and-conditions {
    border-radius: 16px !important;
    width: 100%;
    margin-top: 32px;
  }
  .expansion-container {
    display: flex;
    justify-content: center !important;
    font-family: 'Poppins', sans-serif;
  }
  .documents-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
  }
  .documents-table th, .documents-table td {
    padding: 8px;
    text-align: center;
  }
  .documents-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  .status-icon  {
    color: #6E57F8 !important;
    font-weight: bold;
    font-size: 24px;
  }
</style>

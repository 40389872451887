<template>
  <div>
    <v-card>
      <v-container fluid class="document-information-style">
        <v-row>
          <AlertNotification v-if="alert" :text="error" :type="alertType" />
          <!-- Modal para confirmar la accion de asignar las fechas -->
          <v-dialog
            v-model="confirmDataRangeAssigning"
            width="600px"
            max-width="600px"
            max-height="800px"
            id="confirm-update-folder-dates-dialog"
          >
            <v-card id="delete-card-dialog">
              <v-card-title class="dialog-title">
                Confirm Data Range Assignment
              </v-card-title>
              <v-card-text>
                <span id="text-delete-dialog" class="warning-text">
                  <strong>Wargning: </strong> Assigning a date range to this folder will automatically
                  apply the same range to all documents contained within.
                </span>
                <br>
                <span id="text-delete-dialog" class="description-text">
                  Do you wish to proceed with this setting?.
                </span>
                <br>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="cancel-delete-btn"
                  color="rgb(101,123,219)"
                  text
                  @click="this.confirmDataRangeAssigning = false; this.isEditing = false;"
                >
                  Cancel
                </v-btn>
                <v-btn
                  elevated
                  class="btn-accept"
                  id="confirm-delete-btn"
                  dark
                  @click="editFolderDates(currentFolderId)"
                >
                  Accept and Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col cols="12" class="card-title-custom">
            <v-toolbar-title><span class="bold-text">Folder Information</span></v-toolbar-title>
          </v-col>
        </v-row>
        <v-row class="content-information align-items-center">
          <v-col cols="12" md="7" class="pa-0">
            <strong>Folder: </strong>{{ folderName }}
          </v-col>
          <v-col cols="12" md="12" class="pa-0 mt-6">
            <strong>Date / Time availability range:</strong>
          </v-col>
          <v-row class="datepicker-information">
            <v-col cols="12" md="5" class="pa-0 mt-6">
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                content-class="custom-overlay--top"
                transition="scale-transition"
              >
                <template #activator="{ props }">
                  <v-text-field
                    class="p-calendar"
                    v-model="formattedStartDateTime"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    color="#6E57F8"
                    outlined
                    required
                    :disabled="!isEditing"
                  ></v-text-field>
                </template>
                <v-card class="custom-calendar-card">
                  <p-calendar
                    v-model="startDateTime"
                    showTime
                    inline
                    hourFormat="24"
                    dateFormat="yy-mm-dd"
                    class="calendar-custom"
                  ></p-calendar>
                  <v-card-actions class="custom-card-action">
                    <v-btn text color="primary" @click="closeMenu('start')">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5" class="pa-0 mt-6">
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                content-class="custom-overlay--top"
                transition="scale-transition"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="formattedEndDateTime"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    color="#6E57F8"
                    outlined
                    required
                    :disabled="!isEditing"
                  ></v-text-field>
                </template>
                <v-card class="custom-calendar-card">
                  <p-calendar
                    v-model="endDateTime"
                    showTime
                    inline
                    hourFormat="24"
                    dateFormat="yyyy-mm-dd"
                    class="calendar-custom"
                  ></p-calendar>
                  <v-card-actions class="custom-card-action">
                    <v-btn text color="primary" @click="closeMenu('end')">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <!-- Botones de acciones -->
              <v-col cols="12" md="2" class="buttons-actions-informations mt-6">
              <!-- Botón de confirmación de edición de fechas -->
              <v-btn
                icon
                v-if="isEditing"
                @click="showConfirmDialog(currentFolderId)"
                title="Confirm"
                class="icon-only-btn"
              >
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
              <!-- Botón de edición de fechas -->
              <v-btn
                icon
                v-if="!isEditing"
                @click="showConfirmButton()"
                title="Update"
                class="icon-only-btn"
              >
                <v-icon color="blue">mdi-pencil</v-icon>
              </v-btn>
              <!-- Botón de remover fechas -->
              <v-btn
                icon
                v-if="isEditing"
                @click="removeDates(currentFolderId)"
                :disabled="removeButtonDisabled"
                title="Remove dates"
                class="icon-only-btn"
              >
                <v-icon color="red">mdi-calendar-remove</v-icon>
              </v-btn>
              <!-- Botón de cancelar edición de fechas -->
              <v-btn
                icon
                v-if="isEditing"
                @click="isEditing = false;"
                title="Cancel edit"
                class="icon-only-btn"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { fileServices } from '../services/fileServices';
import DatePicker from 'primevue/datepicker';
import AlertNotification from '../components/AlertNotification.vue';

export default {
  name: 'FolderDataPicker',
  props: {
    folderStartDate: {
      type: String,
      default: null,
    },
    folderName: {
      type: String,
      default: null,
    },
    folderEndDate: {
      type: String,
      default: null,
    },
    currentFolderId: {
      type: Number,
      required: false,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  components: {
    AlertNotification,
    'p-calendar': DatePicker,
  },
  data() {
    return {
      confirmDataRangeAssigning: false,
      isEditing: false,
      startDateTime: null,
      endDateTime: null,
      menuStartDate: false,
      menuEndDate: false,
      error: '',
      alert: false,
      alertType: '',
      authorization: Cookies.get('Authorization'),
      removeButtonDisabled: false,
    };
  },
  computed: {
    formattedStartDateTime() {
      return this.startDateTime ? this.formatDate(this.startDateTime) : '';
    },
    formattedEndDateTime() {
      return this.endDateTime ? this.formatDate(this.endDateTime) : '';
    },
  },
  watch: {
    folderStartDate(val) {
      this.startDateTime = val ? new Date(val) : null;
      this.isEditing = false;
    },
    folderEndDate(val) {
      this.endDateTime = val ? new Date(val) : null;
    },
  },
  methods: {
    handleClickOutside(type) {
      if (type === 'start') {
        this.menuStartDate = false;
      } else if (type === 'end') {
        this.menuEndDate = false;
      }
    },
    closeMenu(type) {
      this.handleClickOutside(type);
    },
    editFolderDates(folderId) {
      const data = {
        start_visualization_date: this.startDateTime,
        end_visualization_date: this.endDateTime,
      };
      fileServices.updateFolderDates(this.authorization, folderId, data)
        .then((response) => {
          this.startDateTime = response.data.start_visualization_date ? new Date(response.data.start_visualization_date) : null;
          this.endDateTime = response.data.end_visualization_date ? new Date(response.data.end_visualization_date) : null;
          this.showAlert('Dates updated correctly', 'success');
          this.isEditing = false;
          this.confirmDataRangeAssigning = false;
        })
        .catch((error) => {
          this.startDateTime = data.start_visualization_date || null;
          this.endDateTime = data.end_visualization_date || null;
          this.confirmDataRangeAssigning = false;
          this.showErrorAlert(
            error && error.data && error.data.non_field_errors
              ? error.data.non_field_errors[0]
              : 'Error trying to modify dates'
          );
        });
    },
    removeDates(folderId) {
      if (this.removeButtonDisabled) return;
      this.removeButtonDisabled = true;
      const data = { start_visualization_date: null, end_visualization_date: null };
      fileServices.updateFolderDates(this.authorization, folderId, data)
        .then(() => {
          this.startDateTime = null;
          this.endDateTime = null;
          this.showAlert('Dates removed correctly', 'success');
          this.isEditing = false;
        })
        .finally(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.removeButtonDisabled = false;
            }, 15000);
          });
        });
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : '';
    },
    showConfirmDialog() {
      this.confirmDataRangeAssigning = true;
    },
    showAlert(message, type) {
      this.alertType = type;
      this.error = message;
      this.alert = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.alertType = '';
          this.alert = false;
        }, 4000);
      });
    },
    showErrorAlert(message) {
      this.showAlert(message, 'error');
    },
    showConfirmButton() {
      this.isEditing = true;
    },
  },
};
</script>

  
<style lang="scss" scoped>
  .v-expansion-panel--active {
    border-radius: 16px !important;
  }
  .datetime-field {
    margin: 2px;
    max-width: 200px;
  }
  .buttons-actions-informations {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .icon-only-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    min-width: auto !important;
  }
  .icon-only-btn:hover {
    background-color: transparent !important;
  }
  .icon-only-btn:disabled {
    opacity: 0.5;
  }
  .buttons-actions-informations v-btn {
    margin: 2px;
  }
  .datepicker-information {
    margin-left: 1px;
  }
  .data-information {
    margin-left: 8px;
    margin-top: -15px;
  }
  .document-info-item {
    margin-left: 10px;
    margin-top: -8px;
  }
  .p-datepicker {
    max-width: 100% !important;
    margin-left: 8px !important;
    margin-bottom: 2px !important;
  }
  .bold-text {
    font-size: 20px;
    font-weight: bold;
  }
  .card-title-custom {
    background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
    padding: 24px; // Espacio alrededor del contenido
    border-top-left-radius: 8px; // Redondear esquina superior izquierda
    border-top-right-radius: 8px; // Redondear esquina superior derecha
    border-bottom-left-radius: 0; // Borde inferior izquierdo sin redondear
    border-bottom-right-radius: 0; // Borde inferior derecho sin redondear
    color: #ffffff; // Color del texto en blanco
    text-transform: uppercase; // Texto en mayúsculas
    width: 100%; // Asegura que ocupe todo el ancho del contenedor
    display: flex; // Alinea el contenido dentro del contenedor
    align-items: center; // Centra verticalmente
    justify-content: space-between; // Alinea el contenido horizontalmente
  }
  .content-information  {
    padding: 24px;
  }
  .custom-calendar-card {
    max-width: 380px;
    margin-left: 4px;
    padding: 5px;
  }
  .custom-card-action {
    display: flex;
    justify-content: flex-end;
  }
  .calendar-custom {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dialog-title {
    font-weight: bold;
    color: white;
    text-align: center;
    background: linear-gradient(
      90deg,
      #6E57F8 0%,
      #14C5E0 100%
    ) !important;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .p-component {
    margin: 10px;
  }
  .p-datepicker {
    width: 100%;
  }
  .p-datepicker-inline {
    display: block;
  }
  .p-datepicker .p-datepicker-header {
    padding: 10px 16px;
  }
  .p-datepicker .p-datepicker-time-panel {
    padding: 8px 16px;
  }
  .warning-text {
    color: red;
    display: block;
    text-align: center;
  }
  .description-text {
    font-size: 18px;
    display: block;
    text-align: center;
    color: grey;
  }
</style>

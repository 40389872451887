<template>
  <v-dialog
    id="edit-user-modal-dialog"
    v-model="updateUserDialog"
    persistent
    max-width="600px"
    class="rounded-xl"
  >
    <v-card id="edit-user-modal-card-dialog" class="title rounded-t-lg">
      <v-card-title class="card-title-custom" primary-title>
        <span id="text-edit-user-dialog" class="bold-text">User Profile</span>
      </v-card-title>
      <v-card-text class="content-information">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                id="email-edit-user-dialog"
                label="Email*"
                color="#6E57F8"
                required
                v-model="userToChange.email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                id="first-name-edit-user-dialog"
                label="First name"
                v-model="userToChange.first_name"
                color="#6E57F8"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                id="last-name-edit-user-dialog"
                label="Last name*"
                v-model="userToChange.last_name"
                persistent-hint
                color="#6E57F8"
                required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="userToChange.company"
                  label="Company*"
                  persistent-hint
                  required
                  color="#6E57F8"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  id="role-edit-user-dialog"
                  v-model="userToChange.company_role"
                  label="Company role*"
                  color="#6E57F8"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-autocomplete
                :items="currentStudiesList"
                id="study-edit-user-dialog"
                v-model="userToChange.studies"
                item-title="name"
                item-text="id"
                label="Study*"
                multiple
                required
                return-object
                color="#6E57F8"
                item-color="#6E57F8"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                v-model="userToChange.role"
                :items="availableRoles"
                label="Role*"
                color="#6E57F8"
                item-color="#6E57F8"
                required
                ></v-select>

            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <v-autocomplete
                :items="regionsList"
                v-model="userToChange.region"
                label="Region"
                color="#6E57F8"
                item-color="#6E57F8"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              md="2"
            >
              <v-text-field
                v-model="userToChange.zip"
                label="Zip*"
                color="#6E57F8"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="close-edit-btn"
          class="btn-cancel"
          text
          @click="cancel()"
        >
          Close
        </v-btn>
        <v-btn
          id="save-edit-btn"
          elevated
          class="btn-accept"
          @click="updateUser(userSelected)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';

export default {
  name: 'EditUserModal',
  props: {
    studiesList: Array,
    updateDialog: {
      type: Boolean,
      default: false,
    },
    users_list: Array,
    userSelected: Object,
    currentId: Number,
    regionsList: Array,
  },
  data() {
    return {
      authorization: Cookies.get('Authorization'),
      updateDataUser: {},
      userChanged: [],
      currentStudiesList: [],
      updateUserDialog: this.updateDialog,
      userToChange: {
        email: '',
        first_name: '',
        last_name: '',
        company: '',
        company_role: '',
        studies: [],
        role: '',
        region: '',
        zip: '',
        regionsList: [],
      },
      availableRoles: ['Admin', 'Viewer', 'Updater'],
    };
  },
  watch: {
    updateDialog(newVal) {
      this.updateUserDialog = newVal;
    },
    updateUserDialog(newVal) {
      this.$emit('update:updateDialog', newVal);
    },
    userSelected: {
      handler(newVal) {
        this.userToChange = { ...newVal };
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.checkSuperadmin();
    this.currentStudiesList = this.studiesList;
    this.userToChange = this.userSelected;
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    checkSuperadmin() {
      if (this.userSelected.role === 'Superadmin') {
        this.availableRoles.push('Superadmin');
      }
    },
    updateUser() {
      this.updateDataUser = {
        first_name: this.userToChange.first_name,
        last_name: this.userToChange.last_name,
        email: this.userToChange.email,
        role: this.userToChange.role,
        study: this.userToChange.studies.map((study) => study.name),
        studyId: this.userToChange.studies.map((study) => study.id),
        studies: this.userToChange.studies,
        company_role: this.userToChange.company_role,
        company: this.userToChange.company,
        region: this.userToChange.region,
        zip: this.userToChange.zip
      };

      userServices.updateUser(this.authorization, this.currentId, this.updateDataUser)
      .then(() => {
        this.userChanged = this.users_list.map(user => {
          if (user.id === this.currentId) {
            return { ...user, ...this.updateDataUser };
          }
          return user;
        });

        this.$emit('updateUsers', this.userChanged);
        this.$emit('updateList', this.updateDataUser);
        this.$emit('confirmEdit');
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .bold-text {
    font-size: 20px;
    font-weight: bold; /* También puedes usar "700" */
  }
  .content-information {
    padding: 24px;
    overflow-x: hidden; /* Ocultar el scroll horizontal */
  }
</style>
